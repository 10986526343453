import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import DefaultLayout from "../components/Layouts/DefaultLayout"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

export default function SponsorSingle({ data }) {

  //Sponsor data
  const sponsorData = data?.wpSponsor

  const images = sponsorData?.hero?.sponsorGallery?.galleryImages?.map(el => el?.image?.localFile?.publicURL)
  // console.log("images", images)

  const [isOpen, setIsOpen] = useState(0);
  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);
  return (
    <>
      <DefaultLayout data={data}>
        <div className="rs-breadcrumbs sec-color">
          <GatsbyImage
            image={getImage(sponsorData?.hero?.heroImage?.localFile)}
            alt={sponsorData?.title}
            className="inner-img"
          />
          <div className="breadcrumbs-inner">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center"
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-delay="5">
                  <h1 className="page-title">{sponsorData?.title}</h1>
                  <ul>
                    <li>
                      <Link className="active" to="/sponsorship">
                        Sponsorship Home &#187;{" "}
                      </Link>
                    </li>
                    <li><a href={sponsorData?.hero?.heroLink?.url} target="_blank" rel="noreferrer"> {sponsorData?.title}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rs-club" style={{ paddingTop: "100px" }}>
          <div className="container">
            <h3 className="title-bg">{sponsorData?.title}</h3>
            <div className="row justify-content-md-center">
              <div className="col-lg-10 col-md-20">
                <div className="rs-club-text">
                  <div
                    dangerouslySetInnerHTML={{ __html: sponsorData?.content }}
                    data-aos="fade-up"
                    data-aos-offset="100"
                    data-aos-delay="5"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Gallery Section Start Here--> */}
        {sponsorData?.hero?.sponsorGallery?.galleryImages && (
          <div className="gallery-section-area-raider sec-spacer">
            <div className="container" id="grid">
              <h3 className="title-bg">{sponsorData?.title}'s gallery</h3>
              <div id="gallery-items">
                <div className="row">
                  {sponsorData?.hero?.sponsorGallery?.galleryImages?.map(el => {
                    return (
                      <div className="col-lg-4 col-md-6">
                        <div className="single-gallery">
                          <GatsbyImage
                            image={getImage(el?.image?.localFile)}
                            alt={"Inner Hero Image"}
                            className="img-gallery"
                          />
                          <div className="heading-conent">
                            <h4>{sponsorData?.title}</h4>
                          </div>
                          {/* <div className="bottom-icons">
                              <ul>
                                  <li>                                             
                                      <FontAwesomeIcon
                                          className="icon-gallery-match"
                                          icon={faPowerOff}
                                          onClick={() => [setIsOpen(true), setPhotoIndex(sponsorData?.hero?.sponsorGallery?.galleryImages?.indexOf(el))]}
                                          style={{cursor:"pointer", width: "15px", height: "15px"}}
                                      />   
                                  </li>
                              </ul>
                          </div> */}
                        </div>
                      </div>
                    )
                  })}
                  {!!isOpen && (
                    <Lightbox
                      mainSrc={images[photoIndex]}
                      nextSrc={images[(photoIndex + 1) % images.length]}
                      prevSrc={
                        images[
                        (photoIndex + images.length - 1) % images.length
                        ]
                      }
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() =>
                        setPhotoIndex(
                          (photoIndex + images.length - 1) % images.length
                        )
                      }
                      onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* <!-- Gallery Section End Here--> */}
      </DefaultLayout>
    </>
  )
}

export const query = graphql`
query ($slug: String!) {
    wpSponsor(slug: { eq: $slug }) {
        uri
        title
        id
        content
        hero {
          sponsorGallery {
            galleryImages {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 440, height: 320)
                  }
                  publicURL
                }
              }
            }
          }
          heroImage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, height: 380)
              }
            }
          }
          heroLink {
            title
            url
          }
        }
    }
}
`